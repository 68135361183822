import React from "react";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

interface IProps {
    className?: string;
    preText?: string | React.ReactNode;
    linkText?: string | React.ReactNode;
}

export const CallLink = (props: IProps) => {
    return (
        <a
            className={props.className}
            href={getPageSetting("phone-number-link")}
        >
            {props.preText}
            {props.preText && " "}
            {props.linkText || getPageSetting("phone-number-display")}
        </a>
    );
};
