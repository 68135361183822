import React from "react";
import { Histogram } from "./Histogram";
import { RatingFilter } from "./RatingFilter";
import { ProductFilter } from "./ProductFilter";
import { SortingSelector } from "./SortingSelector";
import { ReviewList } from "./ReviewList";
import { LoadMoreButton } from "./LoadMoreButton";

interface IProps {
    isLoading: boolean;
    onLoadMore: () => void;
}

export const ReviewsViewer = (props: IProps) => {
    return (
        <div className="view-reviews-layout">
            <div className="region-histogram">
                <Histogram />
            </div>
            <div id="review-menu" className="review-menu">
                <div className="reviews-region-filters">
                    <div className="review-menu-title caption-light">
                        Filter:
                    </div>
                    <RatingFilter />
                    <ProductFilter />
                </div>
                <SortingSelector />
            </div>
            <div className="region-reviews">
                <div className="view-review-set">
                    <ReviewList />
                </div>
            </div>
            <div className="region-load-more">
                <LoadMoreButton
                    isLoading={props.isLoading}
                    onLoadMore={props.onLoadMore}
                />
            </div>
        </div>
    );
};
