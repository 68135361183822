import { Key } from "ts-key-enum";

const openModal = () => {
    const path = window.location.pathname;
    if (path !== "/") {
        return;
    }
    document
        .querySelector(".promo-modal")
        ?.classList.add("promo-modal--active");
};

const openModalSafe = () => {
    const modalCheck = localStorage["promo-modal"];
    if (modalCheck !== "viewed" && document.querySelector(".promo-modal")) {
        // Add appropriate classes to gallery and body
        // uncomment 2 lines below for auto open
        openModal();
    }
};

const closeModal = () => {
    document
        .querySelector(".promo-modal")
        ?.classList.remove("promo-modal--active");
    document.body.classList.remove("modal-open");
};

const toggleModal = () => {
    if (
        document
            .querySelector(".promo-modal")
            ?.classList.contains("promo-modal--active")
    ) {
        closeModal();
        localStorage["promo-modal"] = "viewed";
    } else {
        openModalSafe();
    }
};

// Open handlers
const badgeLinks = document.querySelectorAll(".promo-badge__link");
badgeLinks.forEach((link) => {
    link.addEventListener("click", openModal);
});

const oprahLinks = document.querySelectorAll(".oprah-trigger");
oprahLinks.forEach((link) => {
    link.addEventListener("click", openModal);
});

// Close handlers
const closeLinks = document.querySelectorAll(
    ".promo-modal, .promo-modal__close",
);
closeLinks.forEach((link) => {
    link.addEventListener("click", toggleModal);
});
document.addEventListener("keypress", (e) => {
    if (e.key === Key.Escape) {
        closeModal();
    }
});

// Open modal now
openModalSafe();
