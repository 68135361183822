import React from "react";
import { ISearchFacetOption } from "tsi-common-react/src/models/reviews.interfaces";
import { groupBy, sortBy } from "tsi-common-react/src/utils/functional";

const _defaultGetOptionName = (opt: ISearchFacetOption) => `${opt.name}`;

interface IProps {
    options: ISearchFacetOption[];
    getOptionName?: (opt: ISearchFacetOption) => string;
}

export const FilterOptions = (props: IProps) => {
    const getOptionName = props.getOptionName || _defaultGetOptionName;
    const sortedOptions = sortBy(props.options, "group_sort_order");
    const groupedOptions = groupBy(sortedOptions, "group_id");
    const optGroups = Array.from(groupedOptions).map((group, i) => {
        if (group.length <= 0) {
            return null;
        }
        const groupID = group[0].group_id;
        const groupName = group[0].group_name;
        const options = group.map((opt) => (
            <option key={opt.option_id} value={opt.option_id}>
                {getOptionName(opt)}
            </option>
        ));
        if (groupName) {
            return (
                <optgroup key={groupID} label={groupName}>
                    {options}
                </optgroup>
            );
        }
        return <React.Fragment key={i}>{options}</React.Fragment>;
    });
    return <>{optGroups}</>;
};
