import * as t from "io-ts";
import { nullable } from "tsi-common-react/src/models/utils";
import { ImageURL } from "tsi-common-react/src/models/nominals";
import { VariantHeroBlock } from "tsi-common-react/src/models/streamfield-blocks";

export const ProductPage = t.interface({
    variant_hero_images_with_urls: t.array(VariantHeroBlock),
    hero_image_url: nullable(ImageURL),
    mobile_hero_image_url: nullable(ImageURL),
    hero_image_alt: nullable(t.string),
});
export interface IProductPage extends t.TypeOf<typeof ProductPage> {}
