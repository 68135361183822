import React from "react";
import { parseISO, format as formatDT } from "date-fns";
import { truncate } from "htmlsave";
import { IReview } from "tsi-common-react/src/models/reviews.interfaces";
import { RatingGraphic } from "tsi-common-react/src/common/RatingGraphic";
import { DisclosureCopy } from "tsi-common-react/src/apps/reviews/elements/DisclosureCopy";

interface IProps {
    review: IReview;
}

interface IState {
    isExpanded: boolean;
}

export class Review extends React.Component<IProps, IState> {
    public state: IState = {
        isExpanded: false,
    };

    private readonly onExpand = (evt: React.MouseEvent<HTMLElement>) => {
        evt.preventDefault();
        this.setState({ isExpanded: true });
    };

    private buildContent() {
        if (this.state.isExpanded) {
            return (
                <div className="text content-full">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: this.props.review.text,
                        }}
                    ></span>
                </div>
            );
        }
        const truncatedContent = truncate(this.props.review.text, 420, {
            ellipsis: "…",
        });
        return (
            <div className="text content-truncated">
                <span
                    dangerouslySetInnerHTML={{ __html: truncatedContent }}
                ></span>
                {truncatedContent.indexOf("…") ===
                    truncatedContent.length - 1 && (
                    <button
                        className="show-full"
                        onClick={(evt) => {
                            this.onExpand(evt);
                        }}
                    >
                        &nbsp;Read More
                    </button>
                )}
            </div>
        );
    }

    render() {
        return (
            <div className="view-review">
                <div className="stars-review">
                    <span className="stars-review__stars">
                        <RatingGraphic
                            cardClass="reviews"
                            cardSize="large"
                            onlyStars={true}
                            rating={this.props.review.rating}
                            starHasStroke={true}
                        />
                    </span>
                    <span className="stars-review__product-name">
                        {this.props.review.product_name}
                    </span>
                </div>
                <h2 className="view-review__title">
                    {this.props.review.headline}
                </h2>
                <div className="user-info caption-medium">
                    <span className="user-info__name">
                        {this.props.review.name},
                    </span>
                    &nbsp;
                    <span className="user-info__location-date">
                        {this.props.review.location} -{" "}
                        {formatDT(
                            parseISO(this.props.review.created_datetime),
                            "MM/dd/yy",
                        )}
                    </span>
                </div>
                {this.buildContent()}
                <DisclosureCopy review={this.props.review} />
                <div className="footer"></div>
            </div>
        );
    }
}
