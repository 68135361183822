import React from "react";
import classNames from "classnames";
import { Key } from "ts-key-enum";
import { connect } from "react-redux";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import { defaultState } from "tsi-common-react/src/apps/reviews/defaults";

interface IOwnProps {
    isLoading: boolean;
    onLoadMore: () => void;
}

interface IReduxProps {
    hasMore: boolean;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class LoadMoreButtonComponent extends React.PureComponent<IProps, IState> {
    protected readonly onKeyPressHandler = (event: React.KeyboardEvent) => {
        if (!this.props.onLoadMore) {
            return null;
        }
        if (event.key === Key.Enter) {
            this.props.onLoadMore();
        }
        return null;
    };

    render() {
        if (!this.props.hasMore) {
            return null;
        }
        const loadMoreClasses = classNames({
            "load-more": true,
            "al-reviews__load-more": true,
            "loading": this.props.isLoading,
            "last-page": !this.props.hasMore,
        });
        return (
            <button
                className={loadMoreClasses}
                onClick={this.props.onLoadMore}
                onKeyPress={this.onKeyPressHandler}
            >
                LOAD MORE REVIEWS
            </button>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        hasMore: state.ui.hasMore,
        ...ownProps,
    };
};

export const LoadMoreButton = connect(mapStateToProps)(LoadMoreButtonComponent);
