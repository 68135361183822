import React from "react";
import { formatNumber } from "tsi-common-react/src/utils/format";

export interface IHistogramRow {
    score: number;
    count: number;
}

interface IProps {
    row: IHistogramRow;
    onRatingClick: (
        rating: number,
        event: React.MouseEvent<HTMLElement>,
    ) => void;
    totalCount: number;
}

export const HistogramRow = (props: IProps) => {
    const noun = props.row.score === 1 ? "star" : "stars";
    return (
        <a
            href="#review-menu"
            key={props.row.score}
            onClick={props.onRatingClick.bind(this, props.row.score)}
        >
            <div className="tempur-reviews-total-row">
                <div className="tempur-reviews-total-title caption-medium">
                    {props.row.score} {noun}
                    &nbsp;
                    <span className="tempur-reviews-total-title__count">
                        ({formatNumber(props.row.count)})
                    </span>
                </div>
                <div className="tempur-reviews-total-bar">
                    <progress
                        value={props.row.count}
                        max={props.totalCount}
                    ></progress>
                </div>
            </div>
        </a>
    );
};
