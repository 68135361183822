// Import polyfills and setup error reporting system
import "./src/_bootstrap";
import { Provider, getServerData, getTemplate } from "@reactivated";
import { initPage } from "@thelabnyc/thelabui/src/index";
import Modal from "react-modal";
import { trackPageView } from "tsi-common-react/src/utils/analytics";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import { initWidgets } from "tsi-common-react/src/widgets";
import { registerChatConnector } from "tsi-common-react/src/apps/chat/index";
import { AdaChatConnector } from "tsi-common-react/src/apps/chat/connector-ada";
import { strToBool } from "tsi-common-react/src/utils/format";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

// Load lazysizes
import "lazysizes";

// Load CSS
import "./src/styles/main.scss";

// Set tsi-common-react configuration
import "./src/localization";

// This is the main entry point for new-style thelab-ui / reactivated
// components (which support SSR)
initPage({
    Provider,
    getServerData,
    getTemplate,
}).catch((e) => {
    console.error(e);
});

// Set the root app element for react-modal
if (document.querySelector("#content")) {
    Modal.setAppElement("#content");
} else if (document.querySelector("#root")) {
    Modal.setAppElement("#root");
}

// Configure live chat
const chatEnabled = strToBool(getPageSetting("chat-enabled", "false"));
if (chatEnabled) {
    const hideChat = strToBool(getPageSetting("chat-hide-widget", "true"));
    const chatConnector = new AdaChatConnector({
        settings: {
            handle: "tsi-cocoon",
        },
    });
    registerChatConnector(chatConnector);
    chatConnector.init({
        hideChat: hideChat,
    });
}

// Configure analytics
onDOMContentLoaded.on(() => {
    trackPageView();
});

// Application Components
initWidgets();

import "./src/ui/pdp-video";
import "./src/ui/localization";
import "./src/ui/offers";
import "./src/ui/modal";
import "./src/ui/utility";
import "./src/ui/lazyloading";

// React Components
import "./src/Common/index";
import "./src/Checkout/index";
import "./src/Orders/index";
import "./src/ProductDetail/index";
import "./src/Reviews/index";
import "./src/Offers/index";
import "./src/ProductCompare/index";
import "tsi-common-react/src/blocks/wistia-background-video";
