import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import {
    trapFocus,
    focusElement,
    getFocusableElems,
} from "tsi-common-react/src/utils/keyboardFocus";

window._wq = window._wq || [];

const initVideos = () => {
    const videos = Array.from(
        document.querySelectorAll<HTMLElement>(".mattress-video"),
    );
    const callback = function (videoElem: HTMLElement) {
        const time = Number(videoElem.dataset.start);
        window._wq.push({
            /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
            xsq4: (video: any) => {
                video.bind("play", () => {
                    video.time(time);
                    return video.unbind;
                });
                video.bind("end", () => {
                    video.popover.hide();
                    return video.unbind;
                });
                video.bind("popovershow", () => {
                    const elems = getFocusableElems(
                        video.popover._popoverContainer,
                    );
                    if (elems) {
                        focusElement(elems[0]);
                    }
                    trapFocus(video.popover._popoverContainer);
                    video.play();
                    return video.unbind;
                });
                video.popover.show();
            },
        });
    };

    videos.map((videoElem) => {
        videoElem.addEventListener("click", () => {
            callback(videoElem);
        });
        videoElem.addEventListener("keypress", (event) => {
            const isSpacePressed = event.code === "Space";
            if (isSpacePressed) {
                event.preventDefault();
                callback(videoElem);
            }
        });
    });
};

onDOMContentLoaded.on(() => {
    initVideos();
});
