import { signupForEmailOffers } from "tsi-common-react/src/api/user";

document
    .querySelectorAll<HTMLFormElement>(".offer-subscribe-form")
    .forEach((form) => {
        form.addEventListener("submit", async (event) => {
            event.preventDefault();
            const emailInput = form.querySelector<HTMLInputElement>(
                'input[type="email"]',
            );
            const submitBtn = form.querySelector<HTMLInputElement>(
                'input[type="submit"]',
            );
            if (!emailInput || !submitBtn) {
                return;
            }
            const emailAddr = emailInput.value;

            emailInput.disabled = true;
            submitBtn.disabled = true;

            try {
                await signupForEmailOffers(`${emailAddr}`);
                emailInput.value = "";
                const pseudoPlaceholderSpan = document.querySelector(
                    ".pseudo-placeholder",
                );
                if (!pseudoPlaceholderSpan) {
                    return;
                }
                pseudoPlaceholderSpan.insertAdjacentHTML(
                    "afterend",
                    '<span class="footer-email-alert" role="alert">Thank you!</span>',
                );
            } catch (e) {
                emailInput.disabled = false;
                submitBtn.disabled = false;
            }
        });
    });
