import Flickity from "flickity-imagesloaded";
import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import {
    buildMobileNavToggleClickHandler,
    closeRichNav,
    displayRichNavigationElem,
} from "tsi-common-react/src/widgets/rich-navigation";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

const isElementInViewport = (elem: HTMLElement) => {
    const rect = elem.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
    );
};

const playOnView = (elem: HTMLElement) => {
    // Flag needed for video play functionality (can't unbind scroll because it's used elsewhere)
    let vidStarted = false;
    const vidElem = elem.querySelector<HTMLVideoElement>("video");
    if (!vidElem) {
        return;
    }

    // Window width must be bigger than 1024 - sort of a catchall for iOS/Android
    // Check on page load if element is in view, if not...
    if (isElementInViewport(elem) && window.innerWidth > 1024) {
        if (!vidStarted) {
            vidStarted = true;
            vidElem.play();
        }
    }
    // ...check on page scroll
    window.addEventListener("scroll", () => {
        if (isElementInViewport(elem) && window.innerWidth > 1024) {
            if (!vidStarted) {
                vidStarted = true;
                vidElem.play();
            }
        }
    });
};

// Get document height without iphone's bottom bar
function calculateNavHeight() {
    document.documentElement.style.setProperty(
        "--docInnerHeight",
        `${window.innerHeight}px`,
    );
}

// Scroll behavior for Top Nav
const auxHeader = document.querySelector<HTMLElement>("#aux-header");

// Add simple support for background images:
document.body.addEventListener("lazybeforeunveil", (e) => {
    const target = e.target as HTMLElement;
    const bg = target.getAttribute("data-bg");
    if (bg) {
        target.style.backgroundImage = `url(${bg})`;
    }
});

// Get element's computed style
function getComputedStyles(el: string, prop: string) {
    const element = document.querySelector(el);
    if (element) {
        const compStyles = window.getComputedStyle(element);
        const computedValue = compStyles.getPropertyValue(prop);
        return parseFloat(computedValue);
    }
    return 0;
}

// Calculate basket's sticky height start position
export const getNavHeight = () => {
    const header = document.querySelector<HTMLElement>("header");
    if (header) {
        const style = header.offsetHeight;
        return style + getComputedStyles(".basket-wrapper", "margin-top");
    } else {
        return 0;
    }
};

export const getPreHeaderHeight = () => {
    const prHeader = document.querySelector<HTMLElement>(
        "#content > .pre-header",
    );
    if (!prHeader) {
        return null;
    }
    return prHeader.offsetHeight;
};

// Add class to header when scrolling happens
window.addEventListener("scroll", () => {
    const scrollPos =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
    if (window.innerWidth > 767) {
        if (scrollPos > getNavHeight()) {
            auxHeader?.classList.add("scrolled");
        } else {
            auxHeader?.classList.remove("scrolled");
        }
    } else {
        // Get height without iphone bottom bar
        calculateNavHeight();
    }
});

const expandFirstLinkCollection = () => {
    const richNavigation = document.querySelector<HTMLElement>(
        "nav.rich-navigation",
    );
    const firstCollection = richNavigation?.querySelector<HTMLElement>(
        ".rich-navigation--link-collection",
    );
    const collectionButton =
        firstCollection?.querySelector<HTMLElement>("button");
    const collectionContent =
        firstCollection?.querySelector<HTMLElement>(".collection");

    if (collectionButton && collectionContent) {
        collectionButton.setAttribute("aria-expanded", "true");
        collectionContent.setAttribute("aria-hidden", "false");
    }
};

// When window is resized, toggle the header mobile nav if below 768px
let _lastInnerWidth = window.innerWidth;
window.addEventListener("resize", () => {
    // Only take action on width resizes
    if (_lastInnerWidth === window.innerWidth) {
        return;
    }
    _lastInnerWidth = window.innerWidth;
    if (window.innerWidth <= 768) {
        // Changing to mobile size? Close the nav.
        auxHeader?.classList.add("mobile");
        closeRichNav();
    } else {
        // Changing to desktop size? Open the nav.
        auxHeader?.classList.remove("mobile");
        displayRichNavigationElem("false");
    }
});

// Also check window width on load
if (window.innerWidth <= 768) {
    auxHeader?.classList.add("mobile");
    closeRichNav();
    expandFirstLinkCollection();
    // Get page height on load
    calculateNavHeight();
}

// Make menu visible on large screens since `hide_on_load` is set to true
if (window.innerWidth > 768) {
    const richNavigation = document.querySelector<HTMLElement>(
        "nav.rich-navigation",
    );

    if (richNavigation) {
        richNavigation.setAttribute("aria-hidden", "false");
    }
}

// Mobile Toggles
document.querySelectorAll<HTMLElement>(".mobile-toggle").forEach((elem) => {
    elem.addEventListener("click", buildMobileNavToggleClickHandler(elem));
});

document.querySelectorAll<HTMLElement>(".mobile-nav__title").forEach((elem) => {
    elem.addEventListener("click", (e) => {
        e.preventDefault();
        elem.classList.toggle("mobile-nav__title--close");
        let list = elem.nextElementSibling;
        while (
            !list?.classList.contains("mobile-nav__lists") &&
            list?.nextElementSibling
        ) {
            list = list.nextElementSibling;
        }
        list?.classList.toggle("mobile-nav__lists--close");
    });
});

// If on the homepage...
if (document.body.classList.contains("homepage")) {
    document.querySelectorAll<HTMLElement>(".play-on-view").forEach((elem) => {
        playOnView(elem);
    });
}

// If on the pdp page...
if (document.body.classList.contains("pdp")) {
    // Build carousel for gallery thumbnails in reviews module
    const transformThumbnailsElem = document.querySelector<HTMLElement>(
        "#transform-thumbnails",
    );
    if (transformThumbnailsElem) {
        new Flickity(transformThumbnailsElem, {
            draggable: true,
            wrapAround: true,
            imagesLoaded: true,
            prevNextButtons: false,
            pageDots: false,
        });
    }

    // Build carousel for delivery icons (only visible at $small breakpoint)
    const orderSetupListElem =
        document.querySelector<HTMLElement>(".order-setup-list");
    if (orderSetupListElem) {
        new Flickity(orderSetupListElem, {
            watchCSS: true,
            draggable: true,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: true,
        });
    }

    document
        .querySelectorAll<HTMLElement>("#get-soft-btn, #get-firm-btn")
        .forEach((elem) => {
            elem.addEventListener("click", () => {
                window.scrollTo(0, 0);
            });
        });

    document
        .querySelectorAll<HTMLElement>(".read-more-link")
        .forEach((elem) => {
            elem.addEventListener("click", (event) => {
                event.preventDefault();
                let content = elem.parentElement?.nextElementSibling;
                while (
                    !content?.classList.contains("read-more-content") &&
                    content?.nextElementSibling
                ) {
                    content = content.nextElementSibling;
                }
                if (content) {
                    (content as HTMLElement).style.display = "block";
                }
                elem.style.display = "none";
            });
        });
}

// If on the pdp page...
if (document.body.classList.contains("reviews")) {
    // Build carousel for delivery icons (only visible at $small breakpoint)
    const orderSetupListElem =
        document.querySelector<HTMLElement>(".order-setup-list");
    if (orderSetupListElem) {
        new Flickity(orderSetupListElem, {
            watchCSS: true,
            draggable: true,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: true,
        });
    }
}

if (document.body.classList.contains("story")) {
    document.querySelectorAll<HTMLElement>(".play-on-view").forEach((elem) => {
        playOnView(elem);
    });
}

if (document.body.classList.contains("lookup")) {
    // Build carousel for delivery icons (only visible at $small breakpoint)
    const iconsListElem = document.querySelector<HTMLElement>(
        ".delivery-flow .icons-list",
    );
    if (iconsListElem) {
        new Flickity(iconsListElem, {
            watchCSS: true,
            draggable: true,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: true,
        });
    }
}

// If on the support page...
if (document.querySelector<HTMLElement>("#support")) {
    // Build carousel for delivery icons (only visible at $small breakpoint)
    const orderProcessListElem = document.querySelector<HTMLElement>(
        ".order-process-list",
    );
    if (orderProcessListElem) {
        new Flickity(orderProcessListElem, {
            watchCSS: true,
            draggable: true,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: true,
        });
    }

    // Build carousel for delivery icons (only visible at $small breakpoint)
    const orderSetupListElem =
        document.querySelector<HTMLElement>(".order-setup-list");
    if (orderSetupListElem) {
        new Flickity(orderSetupListElem, {
            watchCSS: true,
            draggable: true,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: true,
        });
    }
}

// If on the order page...
if (document.body.classList.contains("order-detail")) {
    Array.from(
        document.querySelectorAll<HTMLElement>(
            ".order-summary-details__info-column--order-details",
        ),
    ).forEach((elem) => {
        elem.addEventListener("click", function () {
            if (!this.parentNode) {
                return;
            }

            Array.from(this.parentNode.children)
                .filter((e) => {
                    return e.classList.contains(
                        "order-summary-details__info-column--order-details",
                    );
                })
                .forEach((e) => {
                    if (!e.parentNode) {
                        return;
                    }
                    const detailHeader = e.parentNode.parentNode as HTMLElement;
                    detailHeader.classList.toggle(
                        "order-summary-details__header--active",
                    );
                });

            this.classList.toggle(
                "order-summary-details__info-column--order-details--close",
            );
        });
    });
}

// FAQs Accordion
document.querySelectorAll<HTMLButtonElement>(".accordion").forEach((elem) => {
    elem.addEventListener("click", function (event) {
        if ((event.target as HTMLButtonElement).getAttribute("href")) {
            return;
        }
        event.preventDefault();
        this.classList.toggle("accordion--is-active");
        const ariaExpanded = this.getAttribute("aria-expanded");
        if (ariaExpanded === "true" || ariaExpanded === "false") {
            this.setAttribute(
                "aria-expanded",
                ariaExpanded === "true" ? "false" : "true",
            );
        }

        const parentLi = this.closest("li");
        if (!parentLi) {
            return;
        }

        Array.from(parentLi.children)
            .filter((e) => {
                return e.classList.contains("accordion-target");
            })
            .forEach((e) => {
                e.classList.toggle("accordion-target--is-active");

                if (e.classList.contains("accordion-target--is-active")) {
                    e.setAttribute("aria-hidden", "false");
                } else {
                    e.setAttribute("aria-hidden", "true");
                }
            });
    });
});

// Accordion functionality
document.querySelectorAll<HTMLElement>(".accordion").forEach((elem) => {
    elem.addEventListener("click", (e) => {
        e.preventDefault();
        elem.classList.toggle("active");
        elem.nextElementSibling?.classList.toggle("active");
    });
});

// Dynamic Upsell Info Modal Panel Offset CSS Variable
const setUpsellInfoModalPanelOffset = (element: HTMLElement) => {
    const offsetValue = element.getBoundingClientRect().top;
    let offset = `${offsetValue}px`;
    if (offsetValue < 0) {
        offset = "0px";
    }
    document.documentElement.style.setProperty(
        "--configurator--upsell--panel-offset",
        offset,
    );
};

const checkUpsellInfoModalPanelOffset = () => {
    const content = document.getElementById("main-content");
    if (content) {
        setUpsellInfoModalPanelOffset(content);
    }
    window.addEventListener("scroll", () => {
        if (content) {
            setUpsellInfoModalPanelOffset(content);
        }
    });
};

// Open accordion if deep linking
onDOMContentLoaded.on(() => {
    if (window.location.hash) {
        const targetElem = document.querySelector<HTMLElement>(
            window.location.hash,
        );
        if (!targetElem) {
            return;
        }
        targetElem.classList.toggle("active");
        targetElem.nextElementSibling?.classList.toggle("active");
        targetElem.scrollIntoView();
    }

    checkUpsellInfoModalPanelOffset();
});

// nav-header dropdown functionality
document.querySelectorAll<HTMLElement>(".activate-dropdown").forEach((elem) => {
    elem.addEventListener("click", (e) => {
        e.preventDefault();
        const dropdownContainer = document.querySelector<HTMLElement>(
            ".dropdown-container",
        );
        if (!dropdownContainer) {
            return;
        }
        if (!elem.classList.contains("active")) {
            // Background overlay
            const overlay = document.createElement("div");
            overlay.classList.add("dropdown-overlay");
            overlay.addEventListener("click", () => {
                document.body.removeChild(overlay);
                document
                    .querySelector<HTMLElement>(".activate-dropdown")
                    ?.classList.remove("active");
                dropdownContainer.classList.remove("active");
                dropdownContainer.innerHTML = "";
            });
            document.body.appendChild(overlay);

            elem.classList.add("active");
            let menuContent = elem.nextElementSibling;
            while (
                !menuContent?.classList.contains("dropdown-content") &&
                menuContent?.nextElementSibling
            ) {
                menuContent = menuContent.nextElementSibling;
            }
            if (menuContent) {
                menuContent = menuContent.cloneNode(true) as HTMLElement;
                dropdownContainer.classList.add("active");
                dropdownContainer.appendChild(menuContent);
                menuContent.classList.add("active");
            }
        } else {
            elem.classList.remove("active");
            document.querySelector<HTMLElement>(".dropdown-overlay")?.remove();
            dropdownContainer.classList.remove("active");
            dropdownContainer.innerHTML = "";
        }
    });
});

// Promo Callout Dynamic Functionality
document.querySelectorAll<HTMLElement>(".promo_callout_RTF").forEach((elem) => {
    const promoCalloutMessage = getPageSetting("primary-promo-callout-message");
    if (promoCalloutMessage) {
        elem.innerHTML = promoCalloutMessage;
    }
});

// Product Hero Section Sticky Offset
const setProductHeroSectionOffset = () => {
    const content = document.getElementById("main-content");
    if (content) {
        const offset = `${content.getBoundingClientRect().top}px`;
        document.documentElement.style.setProperty(
            "--product-hero--sticky-offset",
            offset,
        );
    }
};
setProductHeroSectionOffset();
