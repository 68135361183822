import React from "react";
import { IProduct } from "tsi-common-react/src/models/catalogue.interfaces";
import { Image } from "tsi-common-react/src/common/Image";
import { RawHTML } from "tsi-common-react/src/common/RawHTML";
import format from "tsi-common-react/src/utils/format";
import { IBasketLineSuggestionProps } from "./BasketLineSuggestion.interfaces";
import { getDinero, getMonthlyPrice } from "tsi-common-react/src/utils/money";
import { Link } from "tsi-common-react/src/common/Link";
import { Dinero } from "dinero.js";

interface IState {}

export class BasketLineSuggestion extends React.Component<
    IBasketLineSuggestionProps,
    IState
> {
    private addToBasket() {
        this.props.addBasketLine(this.props.product.url, 1);
    }

    private scrollToFinePrint(event: React.MouseEvent<HTMLElement>) {
        if (event) {
            event.preventDefault();
        }

        const finePrint = document.querySelector("#basket-lines__fine-print");
        finePrint?.scrollIntoView({ behavior: "smooth" });
    }

    private buildImage(product: IProduct) {
        // Use the product image if one exists else try the parent image
        let image = product.images.slice().shift();
        if (!image && product.parent) {
            image = product.parent.images.slice().shift();
        }
        // TODO: Need link title since content is an image. See #13290
        return image ? (
            <Link href={product.parent?.link || product.link}>
                <Image
                    alt={product.title}
                    src={image.original}
                    className="basket-line__graphic__image"
                />
            </Link>
        ) : null;
    }

    private buildOptions() {
        const attributes = this.props.product.attributes;
        const optionKeys = attributes.product_options?.value || [];
        if (optionKeys.length <= 0) {
            return null;
        }

        const options = optionKeys.map((key) => {
            const attr = attributes[key];
            if (!attr) {
                return null;
            }
            return (
                <li key={key} className="basket-suggestion__details__option">
                    <span>{attr.name}</span>: <strong>{attr.value}</strong>
                </li>
            );
        });

        return (
            <div className="basket-suggestion__details__options">
                <ul>{options}</ul>
            </div>
        );
    }

    private calcPlanMonths(total: Dinero) {
        const plansHTML: HTMLElement[] = [].slice.call(
            document.querySelectorAll(".financing-plan-table__plan"),
        );
        const availablePlans = plansHTML.filter((plan) => {
            return total.greaterThanOrEqual(
                getDinero(plan.dataset.threshold || ""),
            );
        });
        const bestPlan = availablePlans[availablePlans.length - 1];
        return bestPlan ? parseInt(bestPlan.dataset.length || "", 10) : null;
    }

    render() {
        const line = this.props.line;
        const lineProduct = line.product;
        const lineRootProduct = lineProduct.parent || lineProduct;

        const product = this.props.product;
        const rootProduct = product.parent || product;
        const onFinePrintClick = (event: React.MouseEvent<HTMLElement>) => {
            this.scrollToFinePrint(event);
        };

        let financing: JSX.Element | null = null;
        if (product.price.enable_per_month_pricing) {
            // Calculate Suggested plan based on (Cart total + Suggestion Product total) / (Highest plan that total qualifies for)
            const basketTotalPrice = getDinero(this.props.basketTotal);
            const extraProductPrice = getDinero(
                this.props.product.price.cosmetic_excl_tax || "0.00",
            );
            const computedTotalPrice = basketTotalPrice.add(extraProductPrice);
            const planMonths = this.calcPlanMonths(computedTotalPrice);
            if (planMonths) {
                const computedMonthlyPrice = getMonthlyPrice(
                    getDinero(extraProductPrice),
                    planMonths,
                );
                financing = (
                    <p>
                        or{" "}
                        <a onClick={onFinePrintClick}>
                            <span className="basket-suggestion__financing--underline">
                                {format.money(computedMonthlyPrice)}/mo.
                            </span>
                            <sup>2</sup> for{" "}
                            <span className="basket-suggestion__financing--underline">
                                {planMonths} months
                            </span>
                            <sup>1</sup>
                        </a>
                    </p>
                );
            }
        }
        const bundles = line.bundles;
        let headline = "FORGETTING SOMETHING?";

        if (bundles.length > 0) {
            // change the default to 'FORGETTING SOMETHING?'
            headline =
                bundles[0].bundle_group.headline !== "Forget Something?"
                    ? bundles[0].bundle_group.headline
                    : "FORGETTING SOMETHING?";
        }

        return (
            <div className="basket-suggestion">
                <div className="basket-suggestion__row basket-suggestion__row--shaded">
                    <h4>{headline}</h4>
                </div>
                <div className="basket-suggestion__row">
                    <div className="basket-suggestion__graphic">
                        {this.buildImage(product)}
                    </div>
                    <div className="basket-suggestion__details">
                        <h5>BEST SELLER for {lineRootProduct.title}</h5>
                        <h4 className="basket-suggestion__details-title">
                            <Link href={rootProduct.link}>
                                {rootProduct.title}
                            </Link>
                        </h4>
                        <RawHTML
                            className="basket-suggestion__details-description"
                            html={rootProduct.description}
                        />
                        {this.buildOptions()}
                    </div>
                    <div className="basket-suggestion__price">
                        <p>{format.money(product.price.cosmetic_excl_tax)}</p>
                        <div className="basket-suggestion__financing">
                            {financing}
                        </div>
                        <button
                            className="button al-basket-addtocart-upsell"
                            onClick={this.addToBasket.bind(this)}
                        >
                            Add to cart
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
