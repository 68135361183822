import React from "react";
import { connect } from "react-redux";
import {
    TStateMapper,
    TDispatchMapper,
} from "tsi-common-react/src/apps/reducers.interfaces";
import {
    ISearchFacet,
    IReviewQueryFacets,
} from "tsi-common-react/src/models/reviews.interfaces";
import { RatingGraphic } from "tsi-common-react/src/common/RatingGraphic";
import { roundReviewRating } from "tsi-common-react/src/utils/math";
import { defaultState } from "tsi-common-react/src/apps/reviews/defaults";
import { Dispatchers } from "tsi-common-react/src/apps/reviews/dispatchers";
import { IHistogramRow, HistogramRow } from "./HistogramRow";
import { formatNumber } from "tsi-common-react/src/utils/format";

const FACET_TYPE: IReviewQueryFacets = "rating";

interface IOwnProps {}

interface IReduxProps {
    ratingFacet: ISearchFacet | null;
}

interface IDispatchProps {
    updateFilterOptionValue: Dispatchers["updateFilterOptionValue"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

const HistogramComponent = (props: IProps) => {
    if (!props.ratingFacet) {
        return null;
    }
    const totalCount = props.ratingFacet.options
        .map((option) => option.quantity)
        .reduce((memo, qty) => memo + qty, 0);
    const totalScore = props.ratingFacet.options
        .map((option) => option.quantity * parseInt(`${option.name}`, 10))
        .reduce((memo, score) => memo + score, 0);
    const averageScore = totalScore / totalCount;
    const histogram: IHistogramRow[] = props.ratingFacet.options.map(
        (option) => {
            return {
                score: parseInt(`${option.name}`, 10),
                count: option.quantity,
            };
        },
    );
    return (
        <div className="view-reviews-histogram">
            <div className="tempur-reviews-facet-container">
                <div className="tempur-reviews-facet-header">
                    <h2 className="tempur-review-total">Reviews</h2>
                    <div className="tempur-reviews-facet-stars">
                        <RatingGraphic
                            cardClass="reviews"
                            cardSize="large"
                            onlyStars={true}
                            rating={averageScore}
                            starHasStroke={true}
                        />
                    </div>
                    <p className="tempur-review-facet-star-text caption-light">
                        ({roundReviewRating(averageScore)} OUT OF 5){" "}
                        {formatNumber(totalCount)} REVIEWS
                    </p>
                </div>
                <div className="tempur-reviews-total-ratings">
                    {histogram.map((row) => (
                        <HistogramRow
                            key={row.score}
                            row={row}
                            onRatingClick={(rating, event) => {
                                event.preventDefault();
                                props.updateFilterOptionValue(
                                    FACET_TYPE,
                                    rating,
                                    true,
                                    true,
                                );
                            }}
                            totalCount={totalCount}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        ratingFacet:
            state.data.facets.find((facet) => facet.type === FACET_TYPE) ||
            null,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        updateFilterOptionValue: dispatchers.updateFilterOptionValue,
    };
};

export const Histogram = connect(
    mapStateToProps,
    mapDispatchToProps,
)(HistogramComponent);
