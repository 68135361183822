import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { Dispatchers } from "tsi-common-react/src/apps/common/dispatchers";
import { Loaders } from "tsi-common-react/src/apps/common/loaders";
import { registerCascades } from "tsi-common-react/src/apps/common/cascades";
import { snapUserDataLayerState } from "tsi-common-react/src/api/datalayer";
import { isoWebPageURL } from "tsi-common-react/src/models/nominals";
import { readyStateComplete } from "tsi-common-react/src/utils/events";
import { urls } from "tsi-common-react/src/utils/urls";
import { store } from "../store";

// Register store cascades
registerCascades(store);

// Render Session Keep-Alive Modal
dynamicPlaceComponent(
    '[data-place-react="session-keep-alive-modal"]',
    async () => {
        const { SessionKeepAlive } = await import(
            "tsi-common-react/src/apps/authentication/components/SessionKeepAlive"
        );
        return <SessionKeepAlive />;
    },
);

// Render countdown timer
dynamicPlaceComponent('[data-place-react="countdown-timer"]', async (elem) => {
    const { CountdownTimer } = await import(
        "tsi-common-react/src/apps/countdown-timer/container/CountdownTimer"
    );
    const timerData = JSON.parse(elem.dataset.timers || "");
    const timersExpiredPromo = elem.dataset.promo || "";
    return (
        <CountdownTimer
            timerData={timerData}
            timersExpiredPromo={timersExpiredPromo}
        />
    );
});

// Place Live Chat Links
dynamicPlaceComponent('[data-place-react="chat-link"]', async (elem) => {
    const { ChatLink } = await import(
        "tsi-common-react/src/apps/chat/ChatLink"
    );
    return (
        <ChatLink
            className={elem.dataset.chatLinkClass}
            chatOnlineText={elem.dataset.chatOnlineText}
            chatOfflineText={elem.dataset.chatOfflineText}
            chatOfflineLink={
                elem.dataset.chatOfflineLink
                    ? isoWebPageURL.wrap(elem.dataset.chatOfflineLink)
                    : urls.pageURL("support-link")
            }
            hideIfOffline={JSON.parse(elem.dataset.hideIfOffline || "false")}
        />
    );
});

// Render Value Props bar
dynamicPlaceComponent('[data-place-react="value-props-bar"]', async (elem) => {
    const { ValuePropsBar } = await import(
        "tsi-common-react/src/common/ValuePropsBar"
    );
    const parsedData = JSON.parse(elem.dataset.cms || "");
    return <ValuePropsBar valueProps={parsedData.value_props} />;
});

export const dispatchers = new Dispatchers(store.dispatch);
export const loaders = new Loaders(dispatchers);

// Render the CSR toolbar
// Disabled for now due to https://thelabnyc.plan.io/issues/15887#note-14
(async () => {
    const user = await loaders.loadCurrentUser();
    if (!user || !user.is_csr) {
        return;
    }
    // Load the toolbar component
    const { Toolbar } = await import(
        "tsi-common-react/src/apps/csr/components/Toolbar"
    );

    // Create container elements
    const csrToolbarContainer = document.createElement("div");
    csrToolbarContainer.id = "oscarcsr-toolbar-reset";
    document.body.prepend(csrToolbarContainer);

    const csrToolbar = document.createElement("div");
    csrToolbar.id = "oscarcsr-toolbar";
    csrToolbarContainer.appendChild(csrToolbar);

    // Render the toolbar
    createRoot(csrToolbar).render(
        <Provider store={store}>
            <Toolbar
                dashboard={isoWebPageURL.wrap("/store/dashboard/")}
                basket={isoWebPageURL.wrap("/store/basket/")}
            />
        </Provider>,
    );
})();

// Render Sticky email banner
dynamicPlaceComponent(
    '[data-place-react="email-signup-banner"]',
    async (elem) => {
        const { StickyEmailBanner } = await import(
            "tsi-common-react/src/common/StickyEmailBanner"
        );
        const bannerData = JSON.parse(elem.dataset.banner || "");
        return <StickyEmailBanner bannerData={bannerData} />;
    },
);

// Render user data layer element
dynamicPlaceComponent('[data-place-react="user-data-layer"]', async (elem) => {
    await readyStateComplete;
    // Pause for 1 second to allow the data layer push scripts to run after readyState is complete
    await (async () => {
        return new Promise((res) => {
            setTimeout(res, 1000);
        });
    })();
    const orderID = elem.dataset.order || "";
    await snapUserDataLayerState(orderID);
    return null;
});
