import React from "react";
import { FinancingPlan } from "tsi-common-react/src/models/financing";
import { ChatLink } from "tsi-common-react/src/apps/chat/ChatLink";
import { urls } from "tsi-common-react/src/utils/urls";
import { CallLink } from "../../Common/containers/CallLink";
import { BasketFinePrint } from "./BasketFinePrint";

interface IProps {
    plans: FinancingPlan[];
    isMobile: boolean;
    cartID?: string;
}

export const BasketSidebarContent = (props: IProps) => {
    let finePrint: JSX.Element | null = null;
    if (props.isMobile) {
        finePrint = <BasketFinePrint plans={props.plans} />;
    }
    return (
        <div>
            <div className="basket-summary__help">
                <h3>Questions?</h3>
                <p>
                    <ChatLink
                        className="basket-summary__help--link"
                        chatOfflineLink={urls.pageURL("support-link")}
                    />
                    {" or "}
                    <span>
                        <CallLink preText="Call" />
                    </span>
                    <br />
                    {" and reference "}
                    <strong>{props.cartID}</strong>
                </p>
            </div>
            {finePrint}
        </div>
    );
};
