import { EventEmitter } from "events";
import superagent from "superagent";
import { ILocationGuessResponse } from "tsi-common-react/src/models/location.interfaces";
import * as location from "tsi-common-react/src/api/location";

export interface TNTResponse {
    city: string;
    state_province_code: string;
    country_code: string;
    postal_code: string;
    shipping_cost: string;
    state_name: string;
    location_name: string;
    days_in_transit: number;
    arrives_on?: Date;
}

export const arrivalCache: { [s: string]: Promise<TNTResponse> } = {};

const storageObj: { [s: string]: string } = (() => {
    const isStorageSupported = () => {
        if (!window.sessionStorage) {
            return false;
        }
        try {
            window.sessionStorage.setItem("test", "test");
            window.sessionStorage.removeItem("test");
            return true;
        } catch (e) {
            return false;
        }
    };
    return isStorageSupported() ? window.sessionStorage : {};
})();

class ShippingCalc extends EventEmitter {
    public readonly getClientLocation =
        async (): Promise<ILocationGuessResponse | null> => {
            if (storageObj["shipping-client-zip"]) {
                return {
                    zip: storageObj["shipping-client-zip"],
                };
            }
            try {
                const l = await location.guess();
                this.emit("change", l);
                return l;
            } catch (e) {
                return null;
            }
        };

    public readonly setClientLocation = (zip: string) => {
        storageObj["shipping-client-zip"] = zip;
        eventBroker.emit("change", {
            zip: zip,
        });
    };

    public readonly getShipmentArrivalDate = async (
        postalCode: string,
        shipDate?: string,
    ): Promise<TNTResponse | null> => {
        if (!arrivalCache[postalCode]) {
            const qs: { [k: string]: string } = {
                postal_code: postalCode,
            };
            if (shipDate) {
                qs.ship_date = shipDate;
            }
            try {
                const resp = await superagent.get("/api/tnt/").query(qs);
                arrivalCache[postalCode] = {
                    ...resp.body,
                    arrives_on: new Date(resp.body.arrives_on),
                };
            } catch (e) {
                console.error(e);
            }
        }
        return arrivalCache[postalCode] || null;
    };
}

const eventBroker = new ShippingCalc();
export default eventBroker;
